export const EXCLUDE_BILLING_PLANS_IN_UI = [
  'Enterprise',
]

export const BILLING_SECTION_MODALS_KEY = 'billing_section_modals'

export const ALREADY_PAID_ERROR = 'Already paid'
export const ALREADY_PAID_ERROR_MSG = 'You have already paid for this plan. Please refresh the page to see the changes.'

export const SUBSCRIPTION_STATUS_ALLOW_TO_CANCEL = ['active', 'dunning', 'unpaid']
