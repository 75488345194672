import { format, parseISO } from 'date-fns'
import React from 'react'
import * as Modals from 'shared/modals'
import * as GlobalConstants from '../../constants'

export class DomainEmailSettingsModal extends Modals.ModalComponent {
  public static key: string = 'domainEmailSettings'
  constructor(props: Modals.ModalComponentProps) {
    super(props, DomainEmailSettingsModal.key)
  }

  protected renderChildren(data: any) {
    return(
      <span>
        Now take these cname records and enter them where you manage your domain -&nbsp;
        { GlobalConstants.RASA_HELP_HOW_TO_AUTH_DOMAIN &&
          <a href={GlobalConstants.RASA_HELP_HOW_TO_AUTH_DOMAIN} target="_blank">
             read more about that here
          </a>
        }
      </span>)

  }
}

export class SubscriptionCancellationResultModal extends Modals.GenericModalComponent<
  Modals.ModalComponentProps,
  Modals.ModalComponentState
> {
  public static key: string = 'subscriptionCancellationResult'
  constructor(props: Modals.ModalComponentProps) {
    super(props, SubscriptionCancellationResultModal.key)
  }

  protected renderChildren(data: any) {
    return <div>
      { data.endDate
      ?
        <p>
          Your subscription has been canceled, effective {format(parseISO(data.endDate), 'iii, MMM do')}.
          You will still have access to your dashboard until then.
        </p>
      :
        <p>
          Your subscription has been canceled.
        </p>
      }
        <p>
          Your schedule has been paused.  If you would like to continue sending your newsletter until your
          end date, please visit the Schedule page to re-activate it.
        </p>
        <p>
          Please contact <a href={`${GlobalConstants.SUPPORT_MAIL_TO}?subject=Re-Subscribe`}>Customer Support</a> if
          you decided to use our platform again.
        </p>
      </div>
  }

}
